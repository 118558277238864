var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"approval-li"},[_c('div',{staticClass:"row1"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.info.faName))]),_c('div',{staticClass:"right"},[_c('div',{class:[
          'back',
          {
            approving: 1 == _vm.info.faStatus,
            fault: 2 == _vm.info.faStatus,
            scrap: 3 == _vm.info.faStatus,
          },
        ]},[_vm._v(" "+_vm._s(_vm.faStatus)+" ")]),_c('div',{class:{
          doing: true,
          reject: 1 == _vm.info.tagStyle,
          accept: 2 == _vm.info.tagStyle,
          cancel: 3 == _vm.info.tagStyle,
        }},[_vm._v(" "+_vm._s(_vm.tagStyle)+" ")])])]),_c('div',{staticClass:"msg"},[_c('span',[_vm._v("资产编码:"+_vm._s(_vm.info.faCode))])]),_c('div',{staticClass:"msg"},[_c('span',[_vm._v("使用日期:"+_vm._s(_vm.info.useTime || "--"))])]),_c('div',{staticClass:"msg"},[_c('span',[_vm._v("所属部门:"+_vm._s(_vm.info.departmentName || "--"))])]),_c('div',{staticClass:"msg"},[_c('span',[_vm._v("负责人:"+_vm._s(_vm.info.principalName))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }