<!--  -->
<template>
  <div class="approval-li">
    <div class="row1">
      <div class="title">{{ info.faName }}</div>
      <div class="right">
        <!-- v-if="2 == info.faStatus || 3 == info.faStatus" -->
        <div
          :class="[
            'back',
            {
              approving: 1 == info.faStatus,
              fault: 2 == info.faStatus,
              scrap: 3 == info.faStatus,
            },
          ]"
        >
          {{ faStatus }}
        </div>
        <div
          :class="{
            doing: true,
            reject: 1 == info.tagStyle,
            accept: 2 == info.tagStyle,
            cancel: 3 == info.tagStyle,
          }"
        >
          {{ tagStyle }}
        </div>
      </div>
    </div>

    <div class="msg">
      <span>资产编码:{{ info.faCode }}</span>
    </div>
    <div class="msg">
      <span>使用日期:{{ info.useTime || "--" }}</span>
    </div>
    <div class="msg">
      <span>所属部门:{{ info.departmentName || "--" }}</span>
    </div>
    <div class="msg">
      <span>负责人:{{ info.principalName }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  name: "approvalList",
  components: {},
  props: {
    info: Object,
    type: String,
  },
  computed: {
    designValue() {},
    faStatus() {
      const obj = {
        1: "正常",
        2: "故障",
        3: "报废",
      };
      return obj[this.info.faStatus] || "--";
    },
    tagStyle() {
      const obj = {
        1: "未贴",
        2: "已贴",
        3: "无法粘贴",
      };
      return obj[this.info.tagStyle] || "--";
    },
  },
  filters: {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.approval-li {
  width: 7.1rem;
  background: #ffffff;
  border-radius: 0.2rem;
  margin-bottom: 0.2rem;
  margin-left: 0.2rem;
  padding: 0.2rem;
  .row1 {
    display: flex;
    justify-content: space-between;
    .title {
      font-size: 0.3rem;
      font-weight: 600;
      color: #333333;
      line-height: 0.42rem;
    }
    .right {
      display: flex;
      align-items: center;
      .back {
        border-radius: 0.08rem;
        font-size: 0.26rem;
        line-height: 0.36rem;
        padding: 0.06rem 0.12rem;
        margin-left: 0.2rem;
        color: #fff;
      }
      .doing {
        border-radius: 0.08rem;
        font-size: 0.26rem;
        color: #fff;
        line-height: 0.36rem;
        padding: 0.06rem 0.12rem;
        margin-left: 0.2rem;
      }

      .approving {
        background: #1989fa;
      }
      .reject {
        background: #ee0a24;
      }
      .accept {
        background: #07c160;
      }

      .cancel {
        background: #999;
      }
      .scrap {
        background: #ee0a24;
      }
      .fault {
        background: #f7cdc2;
        color: #f65f38;
      }
    }
  }
  .msg {
    word-break: break-all;
    font-size: 0.26rem;
    color: #999999;
    line-height: 0.37rem;
    margin-bottom: 0.12rem;
  }
  .row5 {
    display: flex;
    align-items: center;
    .submit-from {
      height: 0.37rem;
      font-size: 0.26rem;
      color: #333333;
      line-height: 0.37rem;
      flex: 1;
      white-space: nowrap;
      padding: 0.06rem 0;
    }

    .time {
      height: 0.3rem;
      font-size: 0.22rem;
      color: #999999;
      line-height: 0.3rem;
      white-space: nowrap;
    }
  }
}
</style>
