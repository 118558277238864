<!--  -->
<template>
  <div class="approvalList">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '固定资产列表',
        txtColor: '#333',
        isBack: true,
        isMsg: false,
      }"
    /> -->
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="handleLoad"
      v-if="list && list.length"
    >
      <ul class="approval-ul">
        <li v-for="(item, index) in list" :key="index" @click="goDetail(item)">
          <assets-item :info="item" :type="queryParams.type" />
        </li>
      </ul>
    </van-list>
    <van-empty v-else description="暂无数据" :image-size="60" />
  </div>
</template>

<script>
import { queryMyFixedAssetsList } from "@/api/fixedAssets/index.js";
import assetsItem from "./components/assetsItem.vue";
import TopWrapper from "@/components/topWrapper/index.vue";

export default {
  data() {
    return {
      list: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        isSelf: 1, //是否查询自己的固定资产0-否1-是
      },
      loading: false,
      finished: false,
    };
  },
  name: "assetsList",
  components: { assetsItem ,TopWrapper},
  computed: {},
  filters: {},
  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      queryMyFixedAssetsList({
        ...this.queryParams,
      })
        .then((res) => {
          this.list = this.list.concat(res.data.records);
          this.queryParams.pageNum++;
          if (this.list.length >= res.data.total) {
            this.finished = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    goDetail(item) {
      this.$router.push({
        path: "/assetsDetail",
        query: {
          bizCode: item.bizCode,
        },
      });
    },
    handleLoad() {
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.approvalList {
  min-height: 100%;
  background: #fafafa;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  .top {
    padding: 0 0.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .search-box {
      width: 5.92rem;
      height: 0.6rem;
      background: #ffffff;
      border-radius: 0.3rem;
      border: 1px solid #999999;
      display: flex;
      align-items: center;
      .search-icon {
        width: 0.24rem;
        height: 0.24rem;
        margin-left: 0.18rem;
        margin-right: 0.08rem;
      }
      .search-input {
        flex: 1;
        height: 0.4rem;
        background: #ffffff;
        border: none;
        width: 2rem;
      }
      .close-icon {
        height: 0.24rem;
        margin-right: 0.18rem;
      }
    }
    .filter {
      padding: 0 0.1rem;
      .filter-icon {
        widows: 0.24rem;
        height: 0.24rem;
        margin-right: 0.04rem;
      }
      .text {
        height: 0.3rem;
        font-size: 0.28rem;
        font-weight: 500;
        color: #666666;
        line-height: 0.3rem;
      }
    }
  }
  .approval-ul {
    margin-top: 0.2rem;
  }
}
</style>
